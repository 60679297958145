import axios from "axios";
import mainAxios from "../../http/request/main/index";
import evenHub from "../../eventsHub2";


const state = {
  events: [],
  bonuses: [],
  openbetsObj: {},
  profitloss: [],
  changedprofitloss: 0,
  games: localStorage.getItem("games") ? JSON.parse(localStorage.getItem("games")) : [],
};
const getters = {
  getAllGames: (state) => {
    return state.games;
  },

  getBonuses: (state) => {
    return state.bonuses;
  },

  getAllEvents: (state) => {
    return state.events.sort((a, b) => (a.order > b.order ? 1 : -1));
  },

  getEventsBySportName: (state) => (sport) => {
    //console.log(
    //  "sport:",
    //  state.events.find((p) => p.name == sport)
    //);
    return state.events.find((p) => p.name.toLowerCase() == sport)?.events;
  },

  getEventsBySportId: (state) => (sportId) => {
    return state.events.find((p) => p.id == sportId)?.events;
  },

  getInplayEvents: (state) => {
    let inplays = [];
    state.events.forEach((sport) => {
      var eventsOfSport = [];
      sport.events.forEach((game) => {
        if (game.status == "IN_PLAY" || game.matchDate == "In-Play") {
          eventsOfSport.push(game);
        }
      });

      if (eventsOfSport.length > 0) {
        var e = JSON.parse(JSON.stringify(sport));
        e["events"] = [];
        e["events"] = eventsOfSport;
        inplays.push(e);
      }
    });

    return inplays;
  },

  getTwoEventsOfSports: (state) => {
    let twoEvt = [];

    state.events.map((sport) => {
      var eventsOfSport = [];
      let i = 0;
      sport.events.filter((game) => {
        if (i < 2) {
          eventsOfSport.push(game);
          i++;
        }
      });

      if (eventsOfSport.length > 0) {
        var e = JSON.parse(JSON.stringify(sport));
        e["events"] = [];
        e["events"] = eventsOfSport;
        twoEvt.push(e);
      }
    });

    return twoEvt;
  },

  getEventById: (state) => (eventId) => {
    if (eventId) {
      let eventCurrent = null;
      state.events.map((sport) => {
        sport.events.map((e) => {
          if (e.id == eventId) eventCurrent = e;
        });
      });

      return eventCurrent;
    }
    return null;
  },

  getOpenBets: (state) => {
    return state.openbetsObj;
  },

  getOpenBetsByEventId: (state) => (eventId) => {
    if (eventId) {
      if (state.openbetsObj && state.openbetsObj.openBets)
        return state.openbetsObj.openBets.filter((p) => p.eventId == eventId);
      return [];
    } else return [];
  },

  getProfitloss: (state) => {
    return state.profitloss;
  },

  getProfitlossByEventId: (state) => (eventId) => {
    if (eventId) {
      let prof = [];
      state.profitloss.forEach((p) => {
        if (p.eventId == eventId) prof.push(p);
      });
      return prof;
    } else return [];
  },
};

const actions = {
  GetBonuses(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetBonuses(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  Claim(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .Claim(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getBySportName(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetBySportName(payload)
        .then((response) => {
          //console.log("response: ", response);
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getSportWithEventTree({ commit }) {
    return new Promise((resolve, reject) => {
      //mainAxios
      //  .GetSportWithEventTree(payload)
      //  .then((response) => {
      //    console.log("response: ", response);
      //    if (response.data.success == true) {
      //      commit("SET_EVENTS", response.data.result);
      //      resolve(response);
      //    } else {
      //      reject({ message: response.data.error.message });
      //    }
      //  })
      //  .catch((error) => {
      //    console.log("error: ", error);
      //    reject(error);
      //  });

      evenHub.connection.on("BroadcastTreeSportWithEvent", (data) => {
        try {
          // console.log("BroadcastTreeSportWithEvent Called :)", data);
          commit("SET_EVENTS", data);
          resolve(data);
        } catch (err) {
          reject(err);
        }
      });
    }).finally(() => {
      document.getElementById("loading").style.display = "none";
    });
  },

  getSportWithEventTree_Manual({ commit }, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetSportWithEventTree(payload)
        .then((response) => {
          //console.log("response: ", response);
          if (response.data.success == true) {
            commit("SET_EVENTS", response.data.result);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          // console.log("error: ", error);
          reject(error);
        });

      /*evenHub.connection.on("BroadcastTreeSportWithEvent", (data) => {
        try {
          //console.log("BroadcastTreeSportWithEvent Called :)", data);
          commit("SET_EVENTS", data);
          resolve(data);
        } catch (err) {
          reject(err);
        }
      });*/
    }).finally(() => {
      document.getElementById("loading").style.display = "none";
    });
  },

  getByEventId(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetByEventId(payload)
        .then((response) => {
          //console.log("response: ", response);
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getUserOdds(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetUserOdds(payload)
        .then((response) => {
          //console.log("getUserOdds response: ", response);
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          //console.log("getUserOdds error: ", error);
          reject(error);
        });
    });
  },

  closest(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .Closest(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  placeBet(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .PlaceBet(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /*setOpenBets({ commit }) {
    const payload = [
      {
        betId: 1,
        eventId: 321,
        betTitle: "Sri lanka",
        //market: "Cricket India v New ZealandMatch Odds",
        sportId: 1,
        teameName1: "US",
        teameName2: "Sri lanka",
        marketStr: "Match Odds",
        type: "Back",
        odds: 2,
        stake: 200,
        profitLoss: 110,
        betPlaced: "2021-09-28 21:16:02",
        oddsReq: "2021-09-28 21:16:02",
        dateMatched: "2021-09-28 21:16:02",
        fancyValue: 0,
        runnerId: 0,
        marketId: 0,
      },
      {
        betId: 2,
        eventId: 102,
        betTitle: "New ZealandMatch",
        sportId: 1,
        teameName1: "India",
        teameName2: "New ZealandMatch",
        marketStr: "Bookmaker",
        type: "Lay",
        odds: 5,
        stake: 500,
        profitLoss: 520,
        betPlaced: "2021-09-28 21:16:02",
        oddsReq: "2021-09-28 21:16:02",
        dateMatched: "2021-09-28 21:16:02",
      },
      {
        betId: 3,
        eventId: 102,
        betTitle: "India",
        sportId: 1,
        teameName1: "India",
        teameName2: "New ZealandMatch",
        marketStr: "Bookmaker",
        type: "Lay",
        odds: 6,
        stake: 600,
        profitLoss: 660,
        betPlaced: "2021-09-28 21:16:02",
        oddsReq: "2021-09-28 21:16:02",
        dateMatched: "2021-09-28 21:16:02",
      },
    ];

    return new Promise((resolve ) => {

      commit("SET_OPEN_BETS", payload);

      resolve();
      
    });
  },*/

  getUserExpectedProfitLoss({ commit }, context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetUserExpectedProfitLoss(payload)
        .then((response) => {
          if (response.data.success == true) {
            commit("SET_Profiloss", response.data.result);

            localStorage.setItem(
              "ProfitLoss",
              JSON.stringify(response.data.result)
            );
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getUserOpenBets({ commit }, context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetUserOpenBets(payload)
        .then((response) => {
          if (response.data.success == true) {
            commit("SET_OPEN_BETS", response.data.result);
            localStorage.setItem(
              "OpenBets",
              JSON.stringify(response.data.result)
            );
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changedProfitlossAction({ commit }) {
    commit("CHANGED_PROFITLOSS", new Date().getTime());
  },

  getScoreCard(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetScoreCard(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getUserBetHistory(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetUserBetHistory(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getTransactionHistory(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetTransactionHistory(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getBetTransactionHistoryDetail(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetBetTransactionHistoryDetail(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getSetting(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetSetting(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  storeImage(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .StoreImage(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  depositWithdrawalCreate(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .CreateRequestForDepositWithdrawal(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  depositUpiCreate(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .CreateRequestForDepositUpi(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  depositUpiCheck(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .CheckRequestForDepositUpi(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getGames({ commit }, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetGames(payload)
        .then((response) => {
          if (response.data.success == true) {
            localStorage.setItem(
                "games",
                JSON.stringify(response.data.result)
            );
            commit("SET_GAMES", response.data.result);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getDreamCasinoGameUrl(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .DreamCasinoGameUrl(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getKingCasinoGameUrl(context) {
    return new Promise((resolve, reject) => {
      mainAxios
          .KingCasinoGameUrl()
          .then((response) => {
            if (response.data.success == true) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
    });
  },
  getMacCasinoGameUrl(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
          .MacCasinoGameUrl(payload)
          .then((response) => {
            if (response) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
    });
  },
  getInternationalGame(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
          .InternationalGame(payload)
          .then((response) => {
            if (response) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
    });
  },

  getUniversalCasinoTokens(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .UniversalCasinoTokens(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  playerRequests(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .PlayerRequests(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getGrid(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetGrid(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  GetUserLimits(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetUserLimits(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changePassword(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .ChangePassword(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //General
  getIp({ commit }) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetIp()
        .then((response) => {
          if (response.data !== null && response.data !== undefined) {
            localStorage.setItem("ip", response.data);
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  GetPaymentInfo({ commit }) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetPaymentInfo()
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createWithdrawal(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .CreateWithdrawal(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createDeposit(context, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .CreateDeposit(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getUserWithdrawalHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      mainAxios
        .GetUserWithdrawalHistory(payload)
        .then((response) => {
          if (response.data.success == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

//////////////////////////////////////////////////////////////////////////////

const mutations = {
  SET_BONUSES(state, bonuses) {
    state.bonuses = bonuses;
  },

  SET_GAMES(state, val) {
    state.games = val;
  },

  SET_BEARER(state, accessToken) {
    //if (!accessToken) accessToken = localStorage.getItem("accessToken");
    //axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("accessToken");

    localStorage.setItem("oldToken", accessToken);
  },

  SET_EVENTS(state, allEvents) {
    allEvents.map((m) => {
      m.events.map((ev) => {
        ev["sportId"] = m.id;
        ev["sportName"] = m.name;
      });

      // ------------- for order ------------

      if (m.name == "tennis") m["order"] = 3;
      else if (m.name == "cricket") m["order"] = 1;
      else if (m.name == "football") m["order"] = 2;
      else if (m.name == "kabaddi") m["order"] = 4;
      else if (m.name.toLowerCase() == "horseracing") m["order"] = 5;
    });
    state.events = allEvents;
  },

  SET_OPEN_BETS(state, openbets) {
    let userdata = JSON.parse(localStorage.getItem("userinfo"));
    if (userdata?.balance) openbets.balance = userdata.balance;
    if (userdata?.exposure) openbets.exposure = userdata.exposure;

    state.openbetsObj = openbets;
  },

  SET_Profiloss(state, profitloss) {
    state.profitloss = profitloss;
  },

  CHANGED_PROFITLOSS(state, val) {
    state.changedprofitloss = val;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
